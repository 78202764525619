import type { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { createUrlTreeFromSnapshot } from '@angular/router';

import type { GlobalMessagesService } from '../../lib/services/global-messages.service';

/**
 * Shows the missing permission notice & signs the user out of the system.
 * @returns The UrlTree that the user should be redirected to.
 */
export function accessDenied(route: ActivatedRouteSnapshot, globalMessagesService: GlobalMessagesService): UrlTree {
    globalMessagesService.showMissingPermissionsNotice();
    return createUrlTreeFromSnapshot(route, ['/', 'logout']);
}
